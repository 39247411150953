import React from "react";
import { Link } from 'gatsby';

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import Section from "../components/section";

const LegalAdvice = () => {
    return <Layout staticHeader>
        <SEO title="Aviso Legal" description=""/>
        
        <Section title="Aviso Legal">
        <div class="container">
          
          <h3>1. Datos de identificaci&oacute;n</h3>
          <p>Usted est&aacute; visitando la p&aacute;gina Web www.adventurees.com, (en adelante el Sitio Web o la Web), operado por ADVENTUREES ALLIANCE S.L. (en adelante ADVENTUREES), entidad domiciliada en la Calle Santa Teresa de Jornet e Ibars, n&uacute;mero 11, Santa Cruz de Tenerife, 38004 (Espa&ntilde;a), y con CIF B76712066.</p>
          <h3>2. Aceptaci&oacute;n del Usuario</h3>
          <p>Este Aviso Legal regula el acceso y utilizaci&oacute;n del Sitio Web, que ADVENTUREES pone a disposici&oacute;n de los usuarios de Internet (en adelante, &ldquo;el Usuario&rdquo; o &ldquo;los Usuarios&rdquo;).</p>
          <p>El acceso a la misma implica la aceptaci&oacute;n sin reservas del presente Aviso Legal. Asimismo, ADVENTUREES puede ofrecer a trav&eacute;s del Sitio Web servicios que podr&aacute;n encontrarse sometidos a unas condiciones particulares propias sobre las cuales se informar&aacute; al Usuario en cada caso concreto.</p>
          <h3>3. Acceso a la Web y Contrase&ntilde;as</h3>
          <p>Al acceder al Sitio Web de ADVENTUREES el Usuario declara tener capacidad suficiente para navegar por la mencionada Web, esto es ser mayor de dieciocho a&ntilde;os y no estar incapacitado. A su vez, de manera general no se exige la previa suscripci&oacute;n o registro como Usuario para el acceso y uso de la Web, sin perjuicio de que para la utilizaci&oacute;n de determinados servicios o contenidos de la misma se deba realizar dicha suscripci&oacute;n o registro.</p>
          <p>Los datos de los Usuarios obtenidos a trav&eacute;s de la suscripci&oacute;n o registro a la presente Web, est&aacute;n protegidos mediante contrase&ntilde;as elegidas por ellos mismos. El Usuario se compromete a mantener su contrase&ntilde;a en secreto y a protegerla de usos no autorizados por terceros. El Usuario deber&aacute; notificar a ADVENTUREES inmediatamente cualquier uso no consentido de su cuenta o cualquier violaci&oacute;n de la seguridad relacionada con el servicio de la Web, de la que haya tenido conocimiento.</p>
          <p>
            ADVENTUREES adopta las medidas t&eacute;cnicas y organizativas necesarias para garantizar la protecci&oacute;n de los datos de car&aacute;cter personal y evitar su alteraci&oacute;n, p&eacute;rdida, tratamiento y/o acceso no autorizado, habida cuenta del estado de la t&eacute;cnica, la naturaleza de los datos almacenados y los riesgos a que est&aacute;n expuestos, todo ello, conforme a lo establecido por la legislaci&oacute;n espa&ntilde;ola de Protecci&oacute;n de Datos de Car&aacute;cter Personal y en lo referente al uso de Cookies. Los Usuarios pueden ampliar toda esta informaci&oacute;n en la 
            <Link to="privacy-policy">Política de privacidad</Link> y 
            <Link to="cookies-policy">Política de cookies</Link>
            de ADVENTUREES.
          </p>
          <p>ADVENTUREES no se hace responsable frente a los Usuarios, por la revelaci&oacute;n de sus datos personales a terceros que no sea debida a causas directamente imputables a ADVENTUREES, ni por el uso que de tales datos hagan terceros ajenos a</p>
          <h3>4. Uso correcto de la Web</h3>
          <p>El Usuario se compromete a utilizar el Sitio Web, los contenidos y servicios de conformidad con la Ley, el presente Aviso Legal, las buenas costumbres y el orden p&uacute;blico. Del mismo modo el Usuario se obliga a no utilizar el Sitio Web o los servicios que se presten a trav&eacute;s de &eacute;sta con fines o efectos il&iacute;citos o contrarios al contenido del presente Aviso Legal, lesivos de los intereses o derechos de terceros, o que de cualquier forma pueda da&ntilde;ar, inutilizar o deteriorar la Web o sus servicios o impedir un normal disfrute de la Web por otros Usuarios.</p>
          <p>Sin perjuicio de lo anterior, ADVENTUREES puede ofrecer a trav&eacute;s de la Web servicios adicionales que cuenten con su propia regulaci&oacute;n adicional. En estos casos, se informar&aacute; adecuadamente a los Usuarios en cada caso concreto.</p>
          <p>Asimismo, el Usuario se compromete expresamente a no destruir, alterar, inutilizar o, de cualquier otra forma, da&ntilde;ar los datos, programas o documentos electr&oacute;nicos que se encuentren en la Web.</p>
          <p>El Usuario se compromete a no obstaculizar el acceso de otros Usuarios al servicio de acceso mediante el consumo masivo de los recursos inform&aacute;ticos a trav&eacute;s de los cuales ADVENTUREES presta el servicio, as&iacute; como realizar acciones que da&ntilde;en, interrumpan o generen errores en dichos sistemas.</p>
          <p>El Usuario se compromete a no introducir programas, virus, macros, applets, controles ActiveX o cualquier otro dispositivo l&oacute;gico o secuencia de caracteres que causen o sean susceptibles de causar cualquier tipo de alteraci&oacute;n en los sistemas inform&aacute;ticos de ADVENTUREES o de terceros.</p>
          <h3>5. Enlaces de terceros</h3>
          <p>El presente Aviso Legal se refiere &uacute;nicamente a la Web y contenidos de ADVENTUREES, y no se aplica a los enlaces o a las p&aacute;ginas web de terceros accesibles a trav&eacute;s de la Web.</p>
          <p>Los destinos de dichos enlaces no est&aacute;n bajo el control de ADVENTUREES, y el mismo no es responsable del contenido de ninguna de las p&aacute;ginas Web de destino de un enlace, ni de los productos o los servicios prestados por dichos terceros frente a cualesquiera reclamaciones de cualquier naturaleza y demandas que pudieran interponerse en relaci&oacute;n con los mismos, ni de ning&uacute;n enlace incluido en una p&aacute;gina Web a la que se llegue desde la Web de ADVENTUREES, ni de ning&uacute;n cambio o actualizaci&oacute;n de dichas p&aacute;ginas.</p>
          <p>En ning&uacute;n caso los enlaces ser&aacute;n considerados como recomendaci&oacute;n, patrocinio o distribuci&oacute;n por parte de ADVENTUREES de los productos, servicios o contenidos de titularidad de terceros, ofrecidos por &eacute;stos o en cualquier forma divulgados por los mismos.</p>
          <h3>6. Propiedad intelectual</h3>
          <p>Todos los derechos sobre el contenido de la Web, as&iacute; como todos los dise&ntilde;os, im&aacute;genes, mapas, gr&aacute;ficos, marcas, r&oacute;tulos, signos distintivos o logos, frames, banners, el software y sus distintos c&oacute;digos, fuente y objeto, etc. de la Web pertenecen a ADVENTUREES, salvo aqu&eacute;llos de empresas con las que haya firmado el conveniente contrato para la provisi&oacute;n de contenidos y/o servicios, y est&aacute;n protegidos por las normas nacionales e internacionales de propiedad intelectual e industrial.</p>
          <p>Los logos o signos distintivos de otras empresas, que pudieran aparecer en la Web durante la navegaci&oacute;n del Usuario, son titularidad de cada una de ellas, disponiendo leg&iacute;timamente de los derechos de explotaci&oacute;n sobre los mismos y consintiendo que &eacute;stos sean incorporados en la Web de ADVENTUREES.</p>
          <p>Los Usuarios no pueden copiar, modificar, distribuir, transmitir, reproducir, publicar, ceder, vender los elementos anteriormente mencionados, crear nuevos productos o servicios derivados de contenido e informaci&oacute;n de la Web de ADVENTUREES.</p>
          <p>Los Usuarios y terceros podr&aacute;n notificar a ADVENTUREES cualquier infracci&oacute;n del presente Aviso Legal en la direcci&oacute;n de contacto info@adventurees.com. ADVENTUREES har&aacute; sus mayores esfuerzos para que los contenidos de la Web no infrinjan la Ley y/o tengan car&aacute;cter pornogr&aacute;fico, xen&oacute;fobo, discriminatorio, racista o que no fomenten la violencia, desde el momento en el que tenga conocimiento efectivo de dichas infracciones.</p>
          <p>ADVENTUREES espec&iacute;ficamente declara su respeto a los derechos de propiedad intelectual e industrial de terceros, por ello si considera que este sitio puede estar violando sus derechos, rogamos se ponga en contacto con ADVENTUREES a trav&eacute;s de la direcci&oacute;n info@adventurees.com.</p>
          <h3>7. Contenidos, Foros o Blogs</h3>
          <p>ADVENTUREES ofrece a los Usuarios la posibilidad de introducir comentarios y/o de remitir fotograf&iacute;as, para incorporarlos en las secciones correspondientes. La publicaci&oacute;n de los comentarios y/o las fotograf&iacute;as est&aacute; sujeta al presente Aviso Legal.</p>
          <p>La persona identificada en cada caso como la que ha realizado los comentarios y/o ha enviado las fotograf&iacute;as, es responsable de los mismos. Los comentarios y/o las fotograf&iacute;as, no reflejan la opini&oacute;n de ADVENTUREES. Este &uacute;ltimo tampoco hace declaraciones a este respecto.</p>
          <p>ADVENTUREES no se har&aacute; responsable, hasta que no tenga conocimiento efectivo, de los errores, inexactitudes o irregularidades que puedan contener los comentarios y/o las fotograf&iacute;as, as&iacute; como de los da&ntilde;os o perjuicios que se pudieran ocasionar por la inserci&oacute;n de los comentarios y/o las fotograf&iacute;as en el Foro o en las dem&aacute;s secciones de la Web que permitan esta clase de servicios.</p>
          <p>El Usuario suministrador del contenido, del texto y/o fotograf&iacute;as cede a ADVENTUREES los derechos para su reproducci&oacute;n, uso, distribuci&oacute;n, comunicaci&oacute;n p&uacute;blica en formato electr&oacute;nico, en el marco de las actividades para esta Web. Y, en especial, el Usuario cede dichos derechos para el emplazamiento del contenido, del texto y/o las fotograf&iacute;as en la Web, con el fin de que los dem&aacute;s Usuarios del sitio Web puedan acceder a los mismos.</p>
          <p>El Usuario suministrador declara ser el titular de los derechos sobre los contenidos, textos o fotograf&iacute;as o, en su caso, garantiza que dispone de los derechos y autorizaciones necesarios del autor o propietario de los mismos, para su utilizaci&oacute;n por parte de ADVENTUREES a trav&eacute;s de la Web.</p>
          <p>ADVENTUREES no se har&aacute; responsable, a no ser en aquellos extremos a los que obligue la Ley, de los da&ntilde;os o perjuicios que se pudieran ocasionar por el uso, reproducci&oacute;n, distribuci&oacute;n o comunicaci&oacute;n p&uacute;blica o cualquier tipo de actividad que realice sobre los contenidos, textos y/o fotograf&iacute;as que se encuentren protegidos por derechos de propiedad intelectual pertenecientes a terceros, sin que el Usuario haya obtenido previamente de sus titulares la autorizaci&oacute;n necesaria para llevar a cabo el uso que efect&uacute;a o pretende efectuar.</p>
          <p>Asimismo, ADVENTUREES se reserva el derecho de retirar de forma unilateral los contenidos, comentarios y/o fotograf&iacute;as albergados en cualquier otra secci&oacute;n de la Web, cuando ADVENTUREES lo estime oportuno.</p>
          <p>ADVENTUREES no ser&aacute; responsable por la informaci&oacute;n enviada por el Usuario cuando no tenga conocimiento efectivo de que la informaci&oacute;n almacenada es il&iacute;cita o de que lesiona bienes o derechos de un tercero susceptibles de indemnizaci&oacute;n. En el momento que ADVENTUREES tenga conocimiento efectivo de que aloja datos como los anteriormente referidos, se compromete a actuar con diligencia para retirarlos o hacer imposible el acceso a ellos.</p>
          <p>Por todo ello, ADVENTUREES pone a disposici&oacute;n de los Usuarios y de terceros un sistema de denuncia unilateral, por el que los mismos notificar&aacute;n a ADVENTUREES cualquier infracci&oacute;n de lo expuesto aqu&iacute;, de las condiciones del Aviso Legal y cualquiera de sus Pol&iacute;ticas en la siguiente direcci&oacute;n: info@adventurees.com.</p>
          <h3>8. Modificaciones</h3>
          <p>ADVENTUREES se reserva el derecho de modificar, desarrollar o actualizar en cualquier momento y sin previa notificaci&oacute;n las condiciones de uso, el Aviso Legal y cualquiera de sus Pol&iacute;ticas de la Web, siempre respetando la legislaci&oacute;n aplicable en cada momento.</p>
          <p>El Usuario quedar&aacute; obligado autom&aacute;ticamente por las condiciones de uso, el Aviso Legal y cualquiera de las Pol&iacute;ticas de ADVENTUREES que se hallen vigentes en el momento en que acceda a la Web, por lo que deber&aacute; leer peri&oacute;dicamente dichas condiciones de uso, el Aviso Legal y cualquiera de las Pol&iacute;ticas de ADVENTUREES.</p>
          <h3>9. R&eacute;gimen de responsabilidad</h3>
          <p>La utilizaci&oacute;n de la Web es responsabilidad &uacute;nica y exclusiva de cada Usuario, por lo que ser&aacute; responsabilidad suya cualquier infracci&oacute;n o cualquier perjuicio que pueda causar por el uso de la misma. ADVENTUREES, sus socios, colaboradores, empleados y cualquier otro representante o tercero quedan pues exonerados de cualquier responsabilidad que pudieran conllevar las acciones del Usuario.</p>
          <p>ADVENTUREES, a&uacute;n cuando ponga todo su esfuerzo en facilitar siempre informaci&oacute;n actualizada mediante la Web, no garantiza la inexistencia de errores o inexactitudes en ninguno de los contenidos, siempre que sea por razones no imputables de forma directa a ADVENTUREES.</p>
          <p>Por lo anterior, ser&aacute; el Usuario el &uacute;nico responsable frente a todas aquellas reclamaciones o acciones legales iniciadas contra ADVENTUREES basadas en el uso de la Web o sus contenidos por parte del Usuario, siempre que esta utilizaci&oacute;n se haya realizado de forma il&iacute;cita, vulnerando derechos de terceros, o causando cualquier tipo de perjuicio, asumiendo por ello cuantos gastos, costes o indemnizaciones en los que pueda incurrir ADVENTUREES.</p>
          <h3>10. Ley aplicable y Jurisdicci&oacute;n</h3>
          <p>La Web se rige por la legislaci&oacute;n espa&ntilde;ola. ADVENTUREES y el Usuario, con renuncia expresa a su propio fuero, si lo tuvieren, se someten a la jurisdicci&oacute;n de los juzgados y tribunales de la ciudad de S/C de Tenerife (Espa&ntilde;a).</p>
          <p>Sin perjuicio de lo anterior, cuando el Usuario sea considerado como Consumidor podr&aacute; reclamar sus derechos como tal, tanto en Espa&ntilde;a como en su Estado Miembro de residencia en la Uni&oacute;n Europea.</p>
        </div>
        </Section>
    </Layout>
}

export default LegalAdvice;
